import React from 'react';
import { useLocation } from 'react-router-dom';
import AntroLogoWhite from "../../assets/images/antrologowhite.png";
import LinkedInLogo from "../../assets/images/ri_linkedin-fill.png";
import "../../assets/styles/components/footer.scss";

const Footer = () => {
    const location = useLocation();

    const restrictedPaths = [
        "/update-password",
        "/reset-password",
        "/update-username",
        "/reset-username",
        "/corporate-create-password",
        "/invalid-code",
        "/delete-account/"
    ];

    const isRestrictedPage = restrictedPaths.some(path => location.pathname.startsWith(path));

    // const scrollToTop = () => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth'
    //     });
    // };

    return (
        !isRestrictedPage && (
            <div className="main-container-footer">
                <div className="footer-btnup">
                    <div className="footer">
                        <div className="footer-content">
                            <div className="footer-logo">
                                <img src={AntroLogoWhite} alt="Antro Logo" />
                                <p>
                                    An innovative cybersecurity solutions company specializing in identity and access management.
                                    We offer real-time, process-driven solutions designed to protect against identity theft and impersonation.
                                </p>
                            </div>

                            <div className="quick-links">
                                <h3>QUICK LINKS</h3>
                                <ul>
                                    <li><a href="/about-us">About</a></li>
                                    <li>
                                        <a href="/product-info" style={{ fontWeight: "bold", color: "#3c93fb" }}>
                                            Antro-PAI
                                        </a>
                                        <span className="featured-badge">🔥 Featured</span>
                                    </li>
                                    <li><a href="/contact-us">Contact</a></li>
                                </ul>
                            </div>

                            <div className="contact-info">
                                <h3>CONTACT US</h3>
                                <p>Email Address: <a className="contact-email" href="mailto:itadmin@antrocorp.com">itadmin@antrocorp.com</a></p>
                                Location:
                                <p className="contact-address">201, Ram Krishna Nivas Towers, Sanjeeva Reddy Nagar, Hyderabad, Telangana - 500038</p>
                            </div>

                            <div className="social-links">
                                <h3>FOLLOW</h3>
                                <div className="icons">
                                    <a href="https://www.linkedin.com/company/antro-pai-technologies-pvt-ltd/?originalSubdomain=in" target="_blank" rel="noopener noreferrer"><img src={LinkedInLogo} alt="LinkedIn" /></a>
                                </div>
                            </div>
                        </div>

                        <div className="footer-bottom">
                            <p>Copyright 2025. All Rights Reserved.</p>
                            <div className="footer-links">
                                <a href="/terms">Terms & conditions</a>
                                <a href="/privacy-policy">Privacy policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default Footer;
