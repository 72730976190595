import React from 'react';
import { motion } from 'framer-motion';
import '../../assets/styles/pages/services/MissionVision.scss';

const fadeInUp = { hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } };
const staggerChildren = { hidden: { opacity: 0 }, visible: { opacity: 1, transition: { staggerChildren: 0.3 } } };
const MissionVision = () => (
    <motion.section
        className="mission-vision-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={staggerChildren}
    >
        <div className="container">
            <motion.h1 variants={fadeInUp}>Mission & Vision</motion.h1>
            <div className="content-grid">
                <motion.div className="image-content" variants={fadeInUp}>
                    <p className='label-content'>
                        At Antro, our mission is to redefine digital identity verification by providing secure, authenticated profiles, eliminating fraud, and fostering trust across online platforms. We are committed to ensuring seamless and tamper-proof identity verification, empowering businesses and individuals with enhanced security.
                        Our vision is to become the global leader in digital identity solutions, leveraging cutting-edge technologies such as Aadhaar, PAN verification, and real-time selfie authentication to prevent identity duplication and safeguard digital interactions. By prioritizing security, compliance, and user experience, we aim to create a safer, more trustworthy digital ecosystem for businesses and consumers worldwide.
                    </p>
                    <img
                        src="https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-4.0.3&auto=format&fit=crop&w=1350&q=80"
                        alt="Antro Vision"
                        className="vision-image"
                    />
                </motion.div>
                <motion.div className="text-content" variants={fadeInUp}>
                    <ul className="points-list">
                        <motion.li variants={fadeInUp}>
                            <span className="icon">
                                <svg width="24" height="24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 2 L12 22" />
                                    <path d="M2 12 L22 12" />
                                </svg>
                            </span>
                            <div className="side-section-title">
                                <div className='title'>Protect digital identities</div>
                                <div className='subtitle'>Combat identity theft and cyber threats with cutting-edge solutions.</div>
                            </div>
                        </motion.li>
                        <motion.li variants={fadeInUp}>
                            <span className="icon">
                                <svg width="24" height="24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 2 L12 22" />
                                    <path d="M2 12 L22 12" />
                                </svg>
                            </span>
                            <div className="side-section-title">
                                <div className='title'>Empower Businesses with Trust</div>
                                <div className='subtitle'>Provide industries like banking, fintech, and e-commerce with seamless, real-time, and compliant identity verification.</div>
                            </div>
                        </motion.li>
                        <motion.li variants={fadeInUp}>
                            <span className="icon">
                                <svg width="24" height="24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 2 L12 22" />
                                    <path d="M2 12 L22 12" />
                                </svg>
                            </span>
                            <div className="side-section-title">
                                <div className='title'>Enable Seamless Onboarding</div>
                                <div className='subtitle'>Accelerate KYC processes and user verification for businesses, ensuring compliance with global regulatory standards.</div>
                            </div>
                        </motion.li>
                        <motion.li variants={fadeInUp}>
                            <span className="icon">
                                <svg width="24" height="24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 2 L12 22" />
                                    <path d="M2 12 L22 12" />
                                </svg>
                            </span>
                            <div className="side-section-title">
                                <div className='title'>Enhance Online Trust</div>
                                <div className='subtitle'>Ensure seamless and tamper-proof identity verification to build a safer digital ecosystem for businesses and individuals.</div>
                            </div>
                        </motion.li>
                    </ul>
                </motion.div>
            </div>
        </div>
    </motion.section>
);

export default MissionVision;