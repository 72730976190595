import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from "react-router-dom";
import Portfolio2 from '../../assets/images/portfolio/portfolio2.png';
import '../../assets/styles/pages/services/Cover.scss';

const fadeInUp = { hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } };
const staggerChildren = { hidden: { opacity: 0 }, visible: { opacity: 1, transition: { staggerChildren: 0.3 } } };

const Cover = () => {
  const [typedText, setTypedText] = useState("");
  const [index, setIndex] = useState(0);
  const fullText = "The Most Secure Identity Platform You’ll Find";
  const navigate = useNavigate();

  useEffect(() => {
    if (index < fullText.length) {
      const interval = setTimeout(() => {
        setTypedText((prev) => prev + fullText[index]);
        setIndex(index + 1);
      }, 100);

      return () => clearTimeout(interval);
    }
  }, [index, fullText]);

  return (
    <motion.section
      className="cover-section"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={staggerChildren}
    >
      <div className="overlay">
        <motion.div className="content-left" variants={staggerChildren}>
          <motion.h1 variants={fadeInUp}>Welcome to Antro</motion.h1>
          <motion.p className="subtitle" variants={fadeInUp}>
            {typedText}
            <span className="cursor">|</span>
          </motion.p>
          <motion.p className="description" variants={fadeInUp}>
            Our revolutionary platform provides secure, verified digital identities through official government ID verification and real-time live selfie authentication. 
            Using AI-powered facial recognition, we seamlessly match real-time selfies with government-issued documents, preventing fraud and ensuring five times more secure online interactions. 
            With multi-factor authentication, we add extra layers of security, enabling instant KYC, seamless onboarding, and compliance with global regulations. 
            Designed for scalability and top-tier encryption, our solution is ideal for banking, fintech, and e-commerce, ensuring trust, security, and fraud-proof digital transactions.
          </motion.p>
          <motion.div className="cta" variants={fadeInUp}>
            <button className="cta-button" onClick={() => navigate("/contact-us")}>Get Started</button>
          </motion.div>
        </motion.div>
        <motion.div className="image-container" variants={staggerChildren}>
          <motion.img
            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&auto=format&fit=crop&w=1350&q=80"
            alt="User 1"
            className="user-image left"
            variants={fadeInUp}
          />
          <motion.div className="stat left" variants={fadeInUp}>
            150K+<br />Verified Users
          </motion.div>
          <motion.img
            src={Portfolio2}
            alt="User 2"
            className="user-image right"
            variants={fadeInUp}
          />
          <motion.div className="stat right" variants={fadeInUp}>
            500+<br />Partners
          </motion.div>
        </motion.div>
      </div>
      <div className="background-shapes" />
    </motion.section>
  );
};

export default Cover;