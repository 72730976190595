import { motion } from 'framer-motion';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import '../../assets/styles/pages/services/BusinessModel.scss';

const fadeInUp = { hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } };
const staggerChildren = { hidden: { opacity: 0 }, visible: { opacity: 1, transition: { staggerChildren: 0.3 } } };
const BusinessModel = () => (
    <motion.section
      className="business-model-section"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={staggerChildren}
    >
      <div className="container">
        <motion.h1 variants={fadeInUp}>Business Model & Revenue Streams</motion.h1>
        <motion.p className="subheadline" variants={fadeInUp}>
          Driving Sustainable Growth Through Innovation
        </motion.p>
        <div className="content-grid">
          {/* Add Recharts chart here */}
          <motion.div className="chart-container" variants={fadeInUp}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={[
                  { name: 'Subscriptions', value: 40 },
                  { name: 'API Fees', value: 30 },
                  { name: 'Partnerships', value: 20 },
                  { name: 'Ads', value: 10 },
                ]}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#4a5568" />
                <XAxis dataKey="name" stroke="#ffffff" />
                <YAxis stroke="#ffffff" />
                <Tooltip contentStyle={{ background: '#2d3748', border: 'none', color: '#ffffff' }} />
                <Legend wrapperStyle={{ color: '#ffffff' }} />
                <Bar dataKey="value" fill="#3498db" barSize={30} />
              </BarChart>
            </ResponsiveContainer>
          </motion.div>
          <div className="customer">
            <motion.div className="item" variants={fadeInUp}>
              <span className="number">1</span> Target Audience
              <p>Identifying key customer segments including enterprises, fintech firms, e-commerce platforms, and individuals seeking secure digital verification.</p>
            </motion.div>
            <motion.div className="item" variants={fadeInUp}>
              <span className="number">2</span> Core Challenges
              <p>Addressing major pain points such as identity fraud, account duplication, unauthorized access, and compliance risks in digital transactions.</p>
            </motion.div>
            <motion.div className="item" variants={fadeInUp}>
              <span className="number">3</span> Value Proposition
              <p>Providing robust digital identity verification solutions using AI-driven authentication, biometric verification, and secure document validation.</p>
            </motion.div>
            <motion.div className="item" variants={fadeInUp}>
              <span className="number">4</span> Distribution & Integration
              <p>Delivering seamless integration via APIs, SDKs, and enterprise solutions tailored for banking, e-commerce, healthcare, and digital platforms.</p>
            </motion.div>
          </div>
        </div>
        <motion.div className="labels" variants={staggerChildren}>
          <motion.div className="label" variants={fadeInUp}>Requirements & Execution</motion.div>
          <motion.div className="label" variants={fadeInUp}>Customer (Segments) & Solutions</motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
  
  export default BusinessModel;