import React from 'react';
import { motion } from 'framer-motion';
import '../../assets/styles/pages/services/ProblemStatements.scss';

const fadeInUp = { hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } };
const staggerChildren = { hidden: { opacity: 0 }, visible: { opacity: 1, transition: { staggerChildren: 0.3 } } };

const ProblemStatements = () => (
    <motion.section
        className="problem-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={staggerChildren}
    >
        <div className="container">
            <motion.div>
                <motion.h1 variants={fadeInUp}>Problem Statement</motion.h1>
                <motion.p className="description" variants={fadeInUp}>
                    What Problem is Antro Solving?
                </motion.p>
            </motion.div>


            <div className="sticky-notes">
                <motion.div className="note note-1" variants={fadeInUp} whileHover={{ scale: 1.05, rotate: 2 }}>
                    <h2>Identity Fraud</h2>
                    <p>
                        In today’s digital age, identity fraud is rampant, with fake profiles costing businesses and individuals
                        billions annually. Malicious actors exploit weak verification systems, creating multiple accounts to engage
                        in fraudulent activities, undermining trust in online platforms.
                    </p>
                </motion.div>
                <motion.div className="note note-2" variants={fadeInUp} whileHover={{ scale: 1.05, rotate: -2 }}>
                    <h2>Lack of Verification</h2>
                    <p>
                        Traditional online platforms often lack robust identity verification, relying on basic email or phone checks
                        that are easily bypassed. This leaves users vulnerable to impersonation, data breaches, and financial losses.
                    </p>
                </motion.div>
                <motion.div className="note note-3" variants={fadeInUp} whileHover={{ scale: 1.05, rotate: 1 }}>
                    <h2>Multiple Accounts</h2>
                    <p>
                        The ability to create duplicate or fake accounts enables spam, scams, and manipulation, eroding user
                        confidence and platform integrity. Existing solutions fail to prevent this effectively, creating a critical
                        gap in digital security.
                    </p>
                </motion.div>
            </div>
        </div>
    </motion.section>
);

export default ProblemStatements;