import React from 'react';
import { motion } from 'framer-motion';
import '../../assets/styles/pages/services/Roadmap.scss';

const fadeInUp = { hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } };
const staggerChildren = { hidden: { opacity: 0 }, visible: { opacity: 1, transition: { staggerChildren: 0.3 } } };

const Roadmap = () => (
  <motion.section
    className="roadmap-section"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    variants={staggerChildren}
  >
    <div className="container">
      <motion.h1 variants={fadeInUp}>Future Roadmap & Expansion Plans</motion.h1>
      <motion.p className="subheadline" variants={fadeInUp}>
        Charting Antro’s Path to Global Leadership
      </motion.p>
      <div className="timeline">
        <motion.div className="timeline-item" variants={fadeInUp}>
          <div className="year">2023</div>
          <div className="milestone">Milestone #1</div>
          <p>Launch of Antro with Aadhaar and PAN verification, onboarding 100K users.</p>
        </motion.div>
        <motion.div className="timeline-item" variants={fadeInUp}>
          <div className="year">2024</div>
          <div className="milestone">Milestone #2</div>
          <p>Integration of real-time selfie authentication, reaching 500K verified users.</p>
        </motion.div>
        <motion.div className="timeline-item active" variants={fadeInUp}>
          <div className="year">2025</div>
          <div className="milestone">Milestone #3</div>
          <p>Introduction of blockchain storage and expansion into Southeast Asia.</p>
        </motion.div>
        <motion.div className="timeline-item" variants={fadeInUp}>
          <div className="year">2026</div>
          <div className="milestone">Milestone #4</div>
          <p>Launch of desktop version and 5M user milestone in Europe.</p>
        </motion.div>
        <motion.div className="timeline-item" variants={fadeInUp}>
          <div className="year">2027</div>
          <div className="milestone">Milestone #5</div>
          <p>Achieve 10M users globally and lead the $18B digital identity market.</p>
        </motion.div>
      </div>
    </div>
  </motion.section>
);

export default Roadmap;