import React from 'react';
import { motion } from 'framer-motion';
import '../../assets/styles/pages/services/AntroProducts.scss';

const fadeInUp = { hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } };
const staggerChildren = { hidden: { opacity: 0 }, visible: { opacity: 1, transition: { staggerChildren: 0.3 } } };

const AntroProducts = () => (
  <motion.section
    className="antro-products-section"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    variants={staggerChildren}
  >
    <div className="container">
      <motion.h1 variants={fadeInUp}>Antro Corp Products</motion.h1>
      <motion.p className="tagline" variants={fadeInUp}>
        INNOVATIVE TECHNOLOGY MAKES SECURITY BETTER
      </motion.p>
      <div className="antro-products-grid">
        <motion.div className="antro-products-card" variants={fadeInUp}>
          <div className="sphere"></div>
          <h2>Authentic Caller</h2>
          <p>
            Actual identification of caller - The biggest issue of this era.
            The authenticiy of the company numbers.
          </p>
          
        </motion.div>
        <motion.div className="antro-products-card" variants={fadeInUp}>
          <div className="sphere"></div>
          <h2>Visitor Management System</h2>
          <p>
            Dashboard of visitors with their entry and exit appointment is maintained.
          </p>
          
        </motion.div>
        <motion.div className="antro-products-card" variants={fadeInUp}>
          <div className="sphere"></div>
          <h2>Draconian Checkin</h2>
          <p>
            Ids cards, swipe cards can be faked and replicated.
            Biometric delays the process with some technological glitches.
            Display of employees image in screen while checkin through Antro.
          </p>
          
        </motion.div>
        <motion.div className="antro-products-card" variants={fadeInUp}>
          <div className="sphere"></div>
          <h2>Attendance Management System</h2>
          <p>
            Real time trackers of real employees.
          </p>
          
        </motion.div>
      </div>
      <div className="antro-products-grid">
        <motion.div className="antro-products-card" variants={fadeInUp}>
          <div className="sphere"></div>
          <h2>Real-time tracker for field executives</h2>
          <p>
            Reach destination and selfie camera will take care of the rest.
          </p>
          
        </motion.div>
        <motion.div className="antro-products-card" variants={fadeInUp}>
          <div className="sphere"></div>
          <h2>Entry Management</h2>
          <p>
            Seamlessly manage visitor flow with our efficient Entry Management system.
          </p>
          
        </motion.div>
        <motion.div className="antro-products-card" variants={fadeInUp}>
          <div className="sphere"></div>
          <h2>Antro API Plugin</h2>
          <p>
            Any application can integrate for real-time verification of an individual.
          </p>
          
        </motion.div>
        <motion.div className="antro-products-card" variants={fadeInUp}>
          <div className="sphere"></div>
          <h2>Cross Border - International Verification</h2>
          <p>
            Basic information through in-country validation process of international travellers.
          </p>
          
        </motion.div>
      </div>
    </div>
  </motion.section>
);

export default AntroProducts;