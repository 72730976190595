import React from 'react';
import { motion } from 'framer-motion';
import '../../assets/styles/pages/services/TargetAudience.scss';

const fadeInUpAudience = { hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } };
const staggerChildrenAudience = { hidden: { opacity: 0 }, visible: { opacity: 1, transition: { staggerChildren: 0.3 } } };

const TargetAudience = () => (
    <motion.section
        className="audience-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={staggerChildrenAudience}
    >
        <div className="container">
            <motion.h1 variants={fadeInUpAudience}>Target Audience</motion.h1>
            <div className="content-grid">
                <motion.div className="image-content" variants={fadeInUpAudience}>
                    <p className='label-content'>
                        Antro is revolutionizing digital identity verification for governments, financial institutions, corporations, legal professionals, and the public.

                        We will provide secure verification for government agencies like the Army, CID, and police, financial sectors for fraud prevention, and corporations for safer onboarding. Legal professionals benefit from reliable identity checks, while the general public enjoys free tools for online security.

                        By leveraging Aadhaar, PAN verification, and real-time selfie authentication, Antro ensures trust, compliance, and a safer digital ecosystem for all.
                    </p>
                    <img
                        src="https://images.unsplash.com/photo-1607703703520-bb638e84caf2?q=80&w=1470&auto=format&fit=crop"
                        alt="Antro Vision"
                        className="audience-image"
                    />
                </motion.div>
                <motion.div className="text-content" variants={fadeInUpAudience}>
                    <ul className="points-list">
                        <motion.li variants={fadeInUpAudience}>
                            <span className="icon">
                                <svg width="24" height="24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 2 L12 22" />
                                    <path d="M2 12 L22 12" />
                                </svg>
                            </span>
                            <div className="side-section-title">
                                <div className='title'>Government Departments</div>
                                <div className='subtitle'>Army, CID, CBI, ED & Police department.</div>
                            </div>
                        </motion.li>
                        <motion.li variants={fadeInUpAudience}>
                            <span className="icon">
                                <svg width="24" height="24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 2 L12 22" />
                                    <path d="M2 12 L22 12" />
                                </svg>
                            </span>
                            <div className="side-section-title">
                                <div className='title'>Banking & Insurance Sectors</div>
                                <div className='subtitle'>Financial institutions & Insurance companies.</div>
                            </div>
                        </motion.li>
                        <motion.li variants={fadeInUpAudience}>
                            <span className="icon">
                                <svg width="24" height="24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 2 L12 22" />
                                    <path d="M2 12 L22 12" />
                                </svg>
                            </span>
                            <div className="side-section-title">
                                <div className='title'>Corporates</div>
                                <div className='subtitle'>Small, medium, and large enterprises.</div>
                            </div>
                        </motion.li>
                        <motion.li variants={fadeInUpAudience}>
                            <span className="icon">
                                <svg width="24" height="24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 2 L12 22" />
                                    <path d="M2 12 L22 12" />
                                </svg>
                            </span>
                            <div className="side-section-title">
                                <div className='title'>Legal professionals</div>
                                <div className='subtitle'>Legal professionals and law firms.</div>
                            </div>
                        </motion.li>
                        <motion.li variants={fadeInUpAudience}>
                            <span className="icon">
                                <svg width="24" height="24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 2 L12 22" />
                                    <path d="M2 12 L22 12" />
                                </svg>
                            </span>
                            <div className="side-section-title">
                                <div className='title'>General Public</div>
                                <div className='subtitle'>Free subscriptions for broad accessibility.</div>
                            </div>
                        </motion.li>
                    </ul>
                </motion.div>
            </div>
        </div>
    </motion.section>
);

export default TargetAudience;
