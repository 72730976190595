import React from 'react';
import { motion } from 'framer-motion';
import '../../assets/styles/pages/services/CompanyOverview.scss';

const fadeInUp = { hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } };
const staggerChildren = { hidden: { opacity: 0 }, visible: { opacity: 1, transition: { staggerChildren: 0.3 } } };
const CompanyOverview = () => (
  <motion.section
    className="overview-section"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    variants={staggerChildren}
  >
    <div className="container">
      <div className="content-grid">
        <motion.div className="text-content" variants={fadeInUp}>
        <motion.h1 variants={fadeInUp}>Company Overview</motion.h1>
          <p>
          At Antro-PAI, we're pioneers in cybersecurity and software development, driving innovation and excellence in Hyderabad's thriving tech ecosystem. Established in 2023, we've rapidly emerged as a trusted partner for businesses seeking cutting-edge solutions to combat identity theft and stay ahead in the digital landscape.
          </p>
          <p>
          As a beacon of innovation, we're committed to staying ahead of the curve, investing in the latest technologies and trends to ensure our clients receive the most effective and efficient solutions. Our team of experts collaborates closely with clients to understand their challenges and develop personalized solutions that drive real results. With our expertise, innovation, and customer-centric approach, we're the trusted partner of choice for businesses seeking to protect their assets, drive growth, and stay ahead of the competition.
          </p>
        </motion.div>
        <motion.div className="image-content" variants={staggerChildren}>
          <img
            src="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&auto=format&fit=crop&w=1350&q=80"
            alt="Antro Team"
            className="team-image"
          />
          <img
            src="https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&auto=format&fit=crop&w=1350&q=80"
            alt="Technology Interface"
            className="tech-image"
          />
          <img
            src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-4.0.3&auto=format&fit=crop&w=1350&q=80"
            alt="Office Space"
            className="office-image"
          />
        </motion.div>
      </div>
      <motion.div className="stats" variants={staggerChildren}>
        <div className="stat-card">
          <div className="stat-value">75%</div>
          <p>Security</p>
        </div>
        <div className="stat-card">
          <div className="stat-value">50%</div>
          <p>Innovation</p>
        </div>
        <div className="stat-card">
          <div className="stat-value">80%</div>
          <p>Trust</p>
        </div>
      </motion.div>
      <motion.div className="pillars" variants={staggerChildren}>
        <div className="pillar-card">Security</div>
        <div className="pillar-card">Innovation</div>
        <div className="pillar-card">Trust</div>
      </motion.div>
    </div>
  </motion.section>
);

export default CompanyOverview;