import React, { useState, useRef } from "react";
import { motion, useInView } from "framer-motion";
import Services1 from "../../assets/images/portfolio/services1.png";
import "../../assets/styles/pages/services/Services.scss";

const services = [
  {
    title: "Identity Theft Protection",
    description: `
      Protect your personal and business identities from fraud and cyber threats.
      
      - Continuous Dark Web Monitoring to detect leaked credentials.
      - Real-time Fraud Alerts for suspicious activities.
      - Identity Recovery Services for stolen identity assistance.
      - $1 Million Identity Theft Insurance for financial protection.
      - Secure digital verification with AI-powered authentication.
    `,
  },
  {
    title: "Cybersecurity Solutions",
    description: `
      Comprehensive security solutions to protect businesses from digital threats.
      
      - Proactive Threat Detection & Prevention.
      - Data Encryption & Secure Cloud Storage.
      - Endpoint & Network Security to block unauthorized access.
      - Ransomware & Malware Protection.
    `,
  },
  {
    title: "Website Development",
    description: `
      Build high-performance, scalable websites optimized for growth.
      
      - Custom UI/UX designs for a seamless user experience.
      - SEO-optimized structure for better search visibility.
      - Mobile-responsive and high-speed web pages.
      - Secure payment integration for e-commerce platforms.
      - End-to-end support from development to deployment.
    `,
  },
  {
    title: "Mobile App Development",
    description: `
      Create intuitive and feature-rich mobile applications for all platforms.
      
      - Cross-platform app development (iOS & Android).
      - Real-time push notifications and in-app messaging.
      - GPS and Geolocation-based services.
      - Seamless integration with third-party APIs.
      - High security with encrypted data storage.
    `,
  },
  {
    title: "Server Development",
    description: `
      Design scalable and high-performance backend solutions for businesses.
      
      - Microservices architecture for better scalability.
      - Optimized database design for fast performance.
      - Secure API development (REST & GraphQL).
      - Cloud-based hosting and load balancing.
      - Automated backup and disaster recovery.
    `,
  },
  {
    title: "Software Testing",
    description: `
      Ensure software reliability, performance, and security through rigorous testing.
      
      - Functional & UI Testing for bug-free user experience.
      - Security & Penetration Testing to prevent cyber threats.
      - Load & Stress Testing for high-traffic endurance.
      - Automated and Manual Testing for quality assurance.
      - Continuous monitoring and regression testing for updates.
    `,
  },
];

const Services = () => {
  const [expandedIndex, setExpandedIndex] = useState(null); // Set default to null for first load
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.1 });

  const handleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <div className="services" ref={ref}>
      <div className="services-sub-heading">WHAT WE PROVIDE</div>
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        Our Services
      </motion.h2>

      <div className="services__content">
        {/* Left image */}
        <motion.div
          className="services__image"
          initial={{ opacity: 0, x: -50 }}
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <img src={Services1} alt="Services" />
        </motion.div>

        {/* Right accordion */}
        <div className="services__accordion">
          {services.map((service, index) => (
            <div
              key={index}
              className={`service-item ${
                expandedIndex === index ? "expanded" : ""
              }`}
            >
              <div
                className="service-title"
                onClick={() => handleExpand(index)}
              >
                {service.title}
              </div>
              {expandedIndex === index && (
                <motion.div
                  className="service-description"
                  initial={{ opacity: 0, height: 0 }}
                  animate={
                    isInView && expandedIndex === index
                      ? { opacity: 1, height: "auto" }
                      : {}
                  }
                  transition={{ duration: 0.3 }}
                >
                  <p>{service.description}</p>
                </motion.div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
