import React from 'react';
import { motion } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import '../../assets/styles/pages/services/MarketGrowth.scss';

const fadeInUp = { hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } };
const staggerChildren = { hidden: { opacity: 0 }, visible: { opacity: 1, transition: { staggerChildren: 0.3 } } };

const MarketGrowth = () => (
  <motion.section
    className="market-section"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    variants={staggerChildren}
  >
    <div className="container">
      <motion.h1 variants={fadeInUp}>Market Analysis & Growth Opportunity</motion.h1>
      <motion.p className="description" variants={fadeInUp}>
        Exploring the Global Digital Identity Verification Market and Antro’s Growth Potential
      </motion.p>
      <div className="content-grid">
        <motion.div className="text-content" variants={fadeInUp}>
          <p> The global digital identity verification market is expected to reach $18 billion by 2027, driven by the surge in online fraud, evolving regulatory requirements, and increasing digital adoption. India, with its 1.4 billion population and extensive Aadhaar-based digital ecosystem, represents a vast opportunity for Antro to establish itself as a leader in secure identity verification. Our cutting-edge authentication solutions are designed to enhance trust in online interactions, positioning us to capture a substantial share of this rapidly growing market. </p>
          <p> Antro’s expansion strategy focuses on leveraging India’s digital infrastructure while scaling into Southeast Asia, Europe, and North America. By integrating multiple government-issued ID types, blockchain security, and AI-powered verification, we are set to drive innovation and regulatory compliance in identity verification. With a projected 15% CAGR over the next five years, we are on track to reach 10 million verified users by 2026, reinforcing our position as a global leader in digital identity solutions. </p>
        </motion.div>
        <motion.div className="chart-container" variants={fadeInUp}>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={[
                { year: '2023', marketSize: 8, antroGrowth: 0 },
                { year: '2024', marketSize: 10, antroGrowth: 1 },
                { year: '2025', marketSize: 12, antroGrowth: 2.5 },
                { year: '2026', marketSize: 15, antroGrowth: 5 },
                { year: '2027', marketSize: 18, antroGrowth: 8 },
              ]}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#4a5568" />
              <XAxis dataKey="year" stroke="#ffffff" />
              <YAxis stroke="#ffffff" />
              <Tooltip contentStyle={{ background: '#2d3748', border: 'none', color: '#ffffff' }} />
              <Legend wrapperStyle={{ color: '#ffffff' }} />
              <Line type="monotone" dataKey="marketSize" stroke="#3498db" strokeWidth={2} dot={{ r: 4 }} name="Market Size ($B)" />
              <Line type="monotone" dataKey="antroGrowth" stroke="#2ecc71" strokeWidth={2} dot={{ r: 4 }} name="Antro Growth (M Users)" />
            </LineChart>
          </ResponsiveContainer>
        </motion.div>
      </div>
    </div>
  </motion.section>
);

export default MarketGrowth;