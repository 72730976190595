import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import WhyChooseUs1 from '../../assets/images/portfolio/whychooseus1.png';
import WhyChooseUs2 from '../../assets/images/portfolio/whychooseus2.png';
import WhyChooseUs3 from '../../assets/images/portfolio/whychooseus3.png';
import WhyChooseUs4 from '../../assets/images/portfolio/whychooseus4.png';
import WhyChooseUs5 from '../../assets/images/portfolio/whychooseus5.png';
import WhyChooseUs6 from '../../assets/images/portfolio/whychooseus6.png';
import '../../assets/styles/pages/services/WhyChooseUs.scss';

const WhyChooseUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.1 });

  return (
    <div className="why-choose-us" ref={ref}>
        <div>
      <div className='why-choose-us-sub-heading'>Competitive Advantage</div>
      <motion.h2 
        initial={{ opacity: 0, y: -50 }} 
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
        className="why-choose-us__heading"
      >
        Why Choose Antro-PAI?
      </motion.h2>
      </div>
      <div className="why-choose-us__content">
        {/* Left Column */}
        <div className="why-choose-us__column" style={{ marginTop: "50px", borderRight: "1px solid rgb(202 233 255)" }}>
          {[WhyChooseUs1, WhyChooseUs2, WhyChooseUs3].map((image, index) => (
            <motion.div
              key={index}
              className="reason-item"
              initial={{ opacity: 0, y: 50 }}
              animate={isInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="reason-item__icon">
                <img src={image} alt={`Reason ${index + 1}`} />
              </div>
              <div className="reason-item__content">
                {index === 0 && (
                  <>
                    <h3>Pioneering Innovation</h3>
                    <p>We leverage cutting-edge technology to stay ahead of industry trends, ensuring future-ready solutions for our clients.</p>
                  </>
                )}
                {index === 1 && (
                  <>
                    <h3>Tailored Solutions</h3>
                    <p>Our customer-first approach means we craft personalized solutions to meet your business’s unique challenges and objectives.</p>
                  </>
                )}
                {index === 2 && (
                  <>
                    <h3>Unmatched Expertise</h3>
                    <p>With a team of industry veterans and domain specialists, we bring deep technical knowledge to every project, delivering exceptional results.</p>
                  </>
                )}
              </div>
            </motion.div>
          ))}
        </div>

        {/* Right Column */}
        <div className="why-choose-us__column">
          {[WhyChooseUs4, WhyChooseUs5, WhyChooseUs6].map((image, index) => (
            <motion.div
              key={index}
              className="reason-item"
              initial={{ opacity: 0, y: 50 }}
              animate={isInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="reason-item__icon">
                <img src={image} alt={`Reason ${index + 4}`} />
              </div>
              <div className="reason-item__content">
                {index === 0 && (
                  <>
                    <h3>End-to-End Digital Solutions</h3>
                    <p>From secure digital identity verification to full-scale software development, we provide an all-in-one technology suite tailored to your needs.</p>
                  </>
                )}
                {index === 1 && (
                  <>
                    <h3>Rigorous Quality Standards</h3>
                    <p>We follow best-in-class quality assurance protocols to guarantee high-performing, secure, and error-free solutions.</p>
                  </>
                )}
                {index === 2 && (
                  <>
                    <h3>Scalable & Future-Proof</h3>
                    <p>Our adaptable architectures and cloud-native solutions grow alongside your business, ensuring seamless expansion and long-term success.</p>
                  </>
                )}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
