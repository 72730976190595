import React, { useRef } from 'react';
import { MdCall } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";
import { motion, useInView } from 'framer-motion';
import '../../assets/styles/pages/services/Contact.scss';

const ContactUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.1 });

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } }
  };

  return (
    <motion.div
      className="contact-us"
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={containerVariants}
    >
      <motion.h2
        variants={itemVariants}
        className="contact-us__heading"
      >
        Contact Us
      </motion.h2>
      <motion.p
        variants={itemVariants}
        transition={{ delay: 0.2 }}
        className="contact-us__subheading"
      >
        Ready to transform your business with cutting-edge technology?
      </motion.p>

      <div className="contact-us__content">
        {/* Left Side: Contact Information */}
        <motion.div
          className="contact-us__info"
          variants={itemVariants}
          whileHover={{ scale: 1.02, boxShadow: "0 10px 20px rgba(50, 160, 239, 0.3)" }}
        >
          <h3>Contact Information</h3>
          <p className="info-subtitle">Reach Us Anytime, We’re Here to Help</p>
          
          <div className="contact-us__details">
            <div className="contact-us__detail-item">
              <MdCall className="icon" />
              <p><a href="tel:+917097002759">+91-7097002759</a></p>
            </div>
            <div className="contact-us__detail-item">
              <IoMdMail className="icon" />
              <p><a href="mailto:itadmin@antrocorp.com">itadmin@antrocorp.com</a></p>
            </div>
            <div className="contact-us__detail-item">
              <MdLocationPin className="icon" />
              <div>
                <p><strong>Primary:</strong><br />201, SR Nagar, Ramakrishna Niwas Towers, Hyderabad, Telangana 500038, IN</p>
                <p><strong>Secondary:</strong><br />Hyderabad Knowledge City, Raidurgam Panmaktha, Plot No 1/C, Sy No 83/1, Hyderabad, Telangana 500081, IN</p>
              </div>
            </div>
          </div>

          <div className="contact-us__social">
            <p>Connect with us:</p>
            <motion.a
              href="https://www.linkedin.com/company/antro-pai-technologies-pvt-ltd/?originalSubdomain=in"
              target="_blank"
              rel="noopener noreferrer"
              variants={itemVariants}
              whileHover={{ scale: 1.2, color: '#0073b1' }}
              transition={{ type: 'spring', stiffness: 300 }}
            >
              <FaLinkedin />
            </motion.a>
          </div>
        </motion.div>

        {/* Right Side: Contact Form */}
        <motion.div
          className="contact-us__form"
          variants={itemVariants}
          whileHover={{ scale: 1.02, boxShadow: "0 10px 20px rgba(50, 160, 239, 0.3)" }}
        >
          <h3>Let's Start a Conversation!</h3>
          <form>
            <div className="contact-us__form-row">
              <div className="contact-us__form-group">
                <label>First Name</label>
                <input type="text" placeholder="Enter your first name" required />
              </div>
              <div className="contact-us__form-group">
                <label>Last Name</label>
                <input type="text" placeholder="Enter your last name" required />
              </div>
            </div>

            <div className="contact-us__form-row">
              <div className="contact-us__form-group">
                <label>Email</label>
                <input type="email" placeholder="Enter your email" required />
              </div>
              <div className="contact-us__form-group">
                <label>Phone</label>
                <input type="tel" placeholder="+1 (231) 231-2312" required />
              </div>
            </div>
            <div className="contact-us__form-row">
            <div className="contact-us__form-group" style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
              <label>Message</label>
              <textarea placeholder="Tell us what we can help you with..." required></textarea>
            </div>
            </div>

            <div className="contact-us__form-group checkbox-group">
              <input type="checkbox" id="consent" />
              <label htmlFor="consent">
                I agree to the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and consent to receive information.
              </label>
            </div>

            <div className="contact-us__form-buttons">
              <motion.button
                type="button"
                className="btn-cancel"
                variants={itemVariants}
                whileHover={{ backgroundColor: '#e0e0e0', scale: 1.05 }}
                whileTap={{ scale: 0.98 }}
              >
                Cancel
              </motion.button>
              <motion.button
                type="submit"
                className="btn-submit"
                variants={itemVariants}
                whileHover={{ backgroundColor: '#005f8d', scale: 1.05 }}
                whileTap={{ scale: 0.98 }}
              >
                Submit
              </motion.button>
            </div>
          </form>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ContactUs;