import React from 'react';
import { motion } from 'framer-motion';
import '../../assets/styles/pages/services/ProblemSolutions.scss';

const fadeInUp = { hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } };
const staggerChildren = { hidden: { opacity: 0 }, visible: { opacity: 1, transition: { staggerChildren: 0.3 } } };

const ProblemSolutions = () => (
  <motion.section
    className="solution-section"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    variants={staggerChildren}
  >
    <div className="container">
      <motion.h1 variants={fadeInUp}>Our Solution</motion.h1>
      <motion.p className="tagline" variants={fadeInUp}>
        INNOVATIVE TECHNOLOGY MAKES SECURITY BETTER
      </motion.p>
      <div className="solutions-grid">
        <motion.div className="solution-card" variants={fadeInUp}>
          <div className="arrow"></div>
          <h2>Solution One</h2>
          <p>
            Aadhaar Verification: Securely validate user identities using government-issued IDs to ensure authenticity and
            prevent fraud.
          </p>
          <div className="icon">
            <svg width="24" height="24" fill="none" stroke="#3498db" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M12 2 L12 22" />
              <path d="M2 12 L22 12" />
            </svg>
          </div>
        </motion.div>
        <motion.div className="solution-card" variants={fadeInUp}>
          <div className="arrow"></div>
          <h2>Solution Two</h2>
          <p>
            PAN Card Verification: Cross-check financial identities to enhance trust and security for businesses and users.
          </p>
          <div className="icon">
            <svg width="24" height="24" fill="none" stroke="#3498db" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M12 2 L12 22" />
              <path d="M2 12 L22 12" />
            </svg>
          </div>
        </motion.div>
        <motion.div className="solution-card" variants={fadeInUp}>
          <div className="arrow"></div>
          <h2>Solution Three</h2>
          <p>
            Real-Time Selfie Authentication: Use facial recognition to match live selfies with ID photos, ensuring unique
            accounts.
          </p>
          <div className="icon">
            <svg width="24" height="24" fill="none" stroke="#3498db" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M12 2 L12 22" />
              <path d="M2 12 L22 12" />
            </svg>
          </div>
        </motion.div>
        <motion.div className="solution-card" variants={fadeInUp}>
          <div className="arrow"></div>
          <h2>Solution Four</h2>
          <p>
            Multi-Layered Security: Combine ID, selfie, and tech solutions to prevent fraud, ensuring a secure digital
            ecosystem.
          </p>
          <div className="icon">
            <svg width="24" height="24" fill="none" stroke="#3498db" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M12 2 L12 22" />
              <path d="M2 12 L22 12" />
            </svg>
          </div>
        </motion.div>
      </div>
    </div>
  </motion.section>
);

export default ProblemSolutions;